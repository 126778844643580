@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

main{
  background: #F9F5F6 !Important;
}
header{
  position:fixed;
  top:0;
  width: 100%;
  text-align: center;
  font-size: 25px;
  border-bottom: #b3d4fc;
}
h1 {
  font-family: 'Dancing Script';
  font-weight: 400;
}



/* video background */
#background-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

/* page content */

body {
  background: #F9F5F6;
  margin: 0;
  padding: 0;
  text-align: center;
}

h1, h2 {
  color: white;
  font-weight: bold;
  text-align: center;
}

h1 {
  font-size: 7rem;
  margin-top: 0vh;
  margin-bottom: 0vh;
}

h2 { font-size: 4rem;
  margin-bottom: 10vh;
}


main {
  background-color: white;
  font-size: 2rem;
  margin-top: 50vh;
  position: relative;
}

.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.menu{
  float:right;
  font-size: 26px;
  cursor: pointer;
  font-weight:bold;
  background:black;
  opacity: 0.7;
  padding: 8px;

  border:solid 2px white;
}
.menu span,a{
  color:white;
}
.menu a:hover{
  color:#d4004f
}


.connect{
  display:block;
  text-align: center;
  font-size: 4.5rem;
  font-weight: bold;
  color:#fe929f;
  font-family: 'Dancing Script';
  font-weight: 400;
}

.heart{
  width: 250px;
  margin-top: 8vh;
}

h2 img{
  cursor:pointer;
}

.story{
  color:white;
  padding: 1% 5% 5% 5%;

}

.story h1{
  margin-top:40px;
  margin-bottom:20px;
  color: white;
  font-weight: bold;
  text-align: center;

}



.video-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding-bottom: 150px;
}
.video-container {
  width: 50%;
  height:400px
}
.video-container img {
  width: 100%;
  cursor: pointer;
}
footer {
  color:white;
  background-color: black;
  padding: 10px 20px 10px 20px;
  text-align: center;
  font-size: 1.2rem;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;

}

.image-container .image {
  flex: 1 1 20%; /* Adjust as needed for spacing */
  margin-bottom: 20px;
  padding: 20px;
}

.image-container .image img {
  width: 100%;
  height: auto;
  display: block;
}

.image-container .image .description {
  text-align: center;
  margin-top: 5px;
}

.mainPage h2 img{
  height:85px;
}

.mainPage h2 img:first-child {
  margin-right:10px;
}

.mission{
  width: 100%;
  background: black;
}


.pink{
  color:#fe929f !important
}
.story h1{
  font-size: 5rem;
}
.mission-text{
  margin-top:60px;
  font-size:1.9rem;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.mission-text-terms{
  margin-top:60px;
  font-size:3rem;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
  color:#d4004f
}

.terms-text{
  padding:40px;
  margin-top:10px;
  font-size:1.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
  text-align: left;
}
.mainPage{
  height: 100vh;
}

.howItWorks{
  background: #F9F5F6;
  text-align: center;
}
.howItWorks h1 {
padding:1% 0 0 0
}

.howItWorks .mission-text{
  color:black;
}
.videDescription{
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
  font-size: 2rem;
  margin-top:15px
}

.privacy{
  background: #F9F5F6;
  text-align: center;
}
.privacy .mission-text{
  color: black !important;
}

.container {
  display: flex;
  width: 100%;
}
.half {
  flex: 1;
  padding: 10px;
  box-sizing: border-box;
}

.privacyUl{
  color: black;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
  font-size: 1.5rem;
  text-align: center;

}
.privacyUl ul{
  padding: 0;
}
.privacyUl li{

  list-style: none;
}
.privacyUl span{
  font-weight: bold;
}
li>a:hover{
  color:#d4004f;
  font-weight: bold;
}

.largeT{
  list-style: none;
  cursor: pointer;
}

.missionImg{
  position:relative;
  top:0
}


.containerTerms {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.box {
  flex: 1;
  margin: 0 20px; /* Adjust the margin to control the gap */
  box-sizing: border-box;
  padding: 30px; /* Add padding as needed */
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-size: 1.5rem;
}
.largeT{
  font-size: 2rem !important;
}
largeT li a{
  text-decoration: none !important;
}
.largeT{
  padding: 0px;
}
.bColor{
  background-color: #EEEEEE;
  font-size: 1rem;

  text-align: left;
  border-radius: 5px;
  border:solid 1px #D1D8C5
}

.termsStory{
  padding: 1% 5% 1% 5%;
}

.social{
  background: #F1F1F1;
color:black;
}

.social .mission-text{
color: black;
}

.sclContainer{
  text-align: center;
  padding-bottom: 20px;
}

.social .story {
  padding: 1% 5% 1% 5%;
}

.whatAreYouWaiting{
  background: #FEFAF6;

}

.whatAreYouWaiting .story{
  color:black !important;
}
.whatAreYouWaiting img{
  max-width:200px
}
.logo-right {
  padding:10px;
  cursor:pointer;
  position:absolute;
  right:0;
}
.logo-right img{
  margin-top:10px
}
.logo-right span{
   float:right;
   padding:1px 10px 0px 10px;
   font-family: 'Dancing Script';
   font-weight: 400;
    font-size: 3rem;
 }

@media (width <= 800px) {
  /* … */
  .mainPage h1{
    font-size: 5rem;
  }
  .mainPage span{
    font-size: 3rem;
  }

  .heart{
    width: 160px;
    margin-top: 20vh;
  }
  .mainPage h2 img {
    height: 85px;
  }

  .getTheApp h1{
    font-size: 5rem;
  }
  .getTheApp span{
    font-size: 4rem;
  }

  .getTheApp .heart{
    width: 160px;
    margin-top: 10vh;
  }
  .getTheApp  h2 img{
    height: 85px;
  }

  .getTheApp .roboto-thin{
    font-size: 2rem;
  }
}

@media (width > 800px) and (width <= 900px) {
  /* … */
  .mainPage h1{
    font-size: 5rem;
  }
  .mainPage span{
    font-size: 3rem;
  }

  .heart{
    width: 160px;
    margin-top: 20vh;
  }

  .getTheApp .heart{
    margin-top: 10vh;
  }

}
@media (width > 900px) and (width <= 1400px) {
  /* … */
  .mainPage h1{
    font-size: 6rem;
  }
  .mainPage span{
    font-size: 4rem;
  }

  .heart{
    margin-top: 20vh;
  }
  .mainPage h2 img {
    height: 100px;
  }
  .getTheApp .heart{
    margin-top: 10vh;
  }
}


@media  (width <= 1800px){
  .video-row{
    display: block;
  }
  .video-container{
    float: left;
    width: 100%;
clear: both;
    margin-bottom: 10px;
    height:auto;
  }
  .videDescription{
    display: block;
    float: left;
    clear: both;
    width: 100%;
  }
  .video-container iframe{
    width: 80%;
  }



  .mission-text-terms{
    margin-top: 0px;
    padding: 5px;
  }

  .howItWorks,.whatAreYouWaiting{
    display: block;
    float: left;
  }

  .whatAreYouWaiting .box{
    padding: 0;
    margin: 0px 0px 20px 0px;
    width: 33%;
    float: left;
  }
  .whatAreYouWaiting .box img{
    width: 33%;
  }
}

@media (height < 900px){
  /* … */
  .mainPage h1{
    font-size:5rem;
  }
  .mainPage span{
    font-size: 3rem;
  }

  .heart{
    width: 130px;
    margin-top: 20vh;
  }
  .mainPage h2 img {
    height: 80px;
  }
  .getTheApp .heart{
    margin-top: 10vh;
  }
}

@media (height < 500px){
  /* … */
  .mainPage h1{
    font-size: 4rem;
  }
  .mainPage span{
    font-size: 3rem;
  }

  .heart{
    width: 110px;
    margin-top: 20vh;
  }
  .mainPage h2 img {
    height: 80px;
  }
  .getTheApp .heart{
    margin-top: 0vh;
  }

  .getTheApp .heart {
    width: 80px;
  }
}

@media (height < 700px){
  /* … */
  .mainPage h1{
    font-size: 5rem;
  }
  .mainPage span{
    font-size: 3rem;
  }

  .heart{
    width: 120px;
    margin-top: 20vh;
  }
  .mainPage h2 img {
    height: 70px;
  }
}
@media (height < 600px){
  /* … */
  .mainPage h1{
    font-size: 4rem;
  }
  .mainPage span{
    font-size:2.7rem;
  }

  .heart{
    width: 100px;
    margin-top: 20vh;
  }
  .mainPage h2 img {
    height: 50px;
  }


}
@media (width < 1500px) {
  .story h1{
    font-size: 5rem;
    margin-top:100px
  }

  .containerTerms{
    float: left;
    display: block;
  }

  .containerTerms img{
    max-width:300px
  }

}
.whatare{
  float:left;
  width:100%;
  clear: both;
  margin-top:5px;
  font-size: 2rem;
}

@media (width < 1200px) {
  .whatare{
    font-size: 1rem;
  }
}

.sclContainer .box{
  cursor: pointer;
}

@media (width < 950px) {
  .sclContainer .box{
    margin: 0;
    padding: 0;
    margin-top:20px;
    font-size: 1rem;
  }
  .sclContainer .box img{
    width: 30px;
  }

  .privacySite .container{
    display:block;
  }
}

.clipSlogan{
  padding: 15px 30px 15px 30px;
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;

  margin-bottom: 10vh;
}

.slogan{
  font-weight: bold;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black
}

.sclContainer{
  padding: 50px;
}

.whatAreYouWaiting .container{
  padding: 0px 0px 40px 0px;
}

.whatAreYouWaiting .story{
  padding-bottom: 2%;
}

.privacyUl a{
  color:black;
}

.privacySite .mission-text{
  margin-top: 0px;
}

.privacySite .story{
  padding-bottom: 0;
}

.privacySite .story .container{
  margin-top:  30px;
}

.privacyTermsText a, .largeT a{
  color: black;
}

.chevronBack{
  position:absolute;
  top:-20px;
  left:-5px;
  color:black;
  width:20px;
  height: 20px;
}

.chevronBack a{
  color:black;

  font-size: 80px;
  margin-left: 20px;
  margin-top:20px;
}

.sclContainer a{
  color:black;
}

.sclContainer a{
  text-decoration: none;
}